var log = require("adaptor/log");
var platform = require("platform");
var state = require("platform/state");
var stash = require("adaptor/stash");
var _ = require("adaptor/utils");
var capabilities = require("platform/capabilities");
var $ = require("platform/jquery");
var JSON = window.JSON;

var user = state.user;
var page = state.page;
var product = page.product;
var cart = state.cart;
var order = state.order;

page.type = getPageType();

if (page.type === "order") {
  trackOrder();
}
else {
  trackPage();
}

/*
Get a Demo : /get-a-demo-submitted
Tech Support: /tech-support-submitted
Become a Partner: /become-a-partner-submitted
*/
if (window.location.pathname === "/contact-us") {
  $('.submit-button.w-button').on('click', function () {
    page.url = "https://www.fanplayr.com/contact-us/" + $(".tab_link.w-tab-link.w--current").text().toLowerCase().replace(/ /g, "-") + '-submitted';
    trackPage();
  });
} else {
  $('.submit-button.w-button').on('click', function () {
    log("submit button clicked");
    page.url = window.location.href.replace(/\/$/, '') + "/get-a-demo-submitted";
    trackPage();
  });
}

if (_.parseUrl(window.location.href).params.contactform) {
  $("#ContactForm-wrapper")[0].scrollIntoView();
}

function getPageType() {
  if (window.location.pathname === "/") {
    return "home";
  }
  return "page";
}

function trackPage() {
  // if (page.type === "cart") {
  //   log(JSON.stringify(state.cart, null, 2));
  // } else {
  //   log(JSON.stringify(state.page, null, 2));
  // }
  platform.trackPage();
}

function trackOrder() {
  // log(JSON.stringify(state.order, null, 2));
  platform.trackOrder();
}
