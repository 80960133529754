// Fanplayr Adaptor (fanplayr-website) v1.0.4
// Hodor v1.5.0
(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var Emitter = require("./emitter");

// `adaptor` is declared at the top of the IIFE during the build process.
var adaptor = module.exports = {};

var fanplayr = window.fanplayr = window.fanplayr || {};
adaptor.api = window.fanplayr_api = window.fanplayr_api || [];

Emitter.mixin(adaptor);

var log = adaptor.log = require("./log");
var config = adaptor.config = require("./config");
var _ = adaptor.utils = require("./utils");

adaptor.isInFrame = function () {
  var result = true;
  try {
    var w = window;
    result = w.top === w || w.top === w.self || !w.frameElement;
  } catch (ex) {}
  return !result;
};

var isCollector = config.collector;

if (config.version) {
  adaptor.version = config.version;
}

var didInit;
function init(fanplayr) {
  if (!didInit) {
    didInit = true;

    if (config.accountKey) {
      fanplayr.platform.config.accountKey = config.accountKey;
    }

    var platformUtils = fanplayr.require("platform/utils");
    for (var key in platformUtils) {
      _[key] = platformUtils[key];
    }

    adaptor.createStash = fanplayr.require("platform/stash").create;

    var stash = adaptor.stash = adaptor.createStash(_.merge({
      name: "adaptor",
      scope: "domain"
    }, config.stash || {}));

    adaptor.fanplayr = fanplayr;
    adaptor.$ = fanplayr.$;
    adaptor._ = _;

    stash.load(function () {
      var entry = require('__project/main.js');

      fanplayr.adaptor = _.merge({}, adaptor, entry || {});

      adaptor.emit("ready", fanplayr, fanplayr.$, _);
    });
  }
}

var isInFrame = adaptor.isInFrame();
if (!isInFrame || fanplayr.allowInFrame || config.allowInFrame) {
  // Prevent adaptor from loading multiple times.
  if (!fanplayr.adaptor || isCollector) {
    fanplayr.adaptor = adaptor;

    var initPlatform = function initPlatform() {
      // Boot fanplayr.
      require("./fanplayr-init")(init, adaptor.environment);
    };

    var bootFn = require('__project/boot.js');
    if (typeof bootFn === "function") {
      bootFn(initPlatform);
    } else {
      // Give implementing adaptor a chance to modify `adaptor.environment`.
      setTimeout(initPlatform, 0);
    }
  } else {
    log("Adaptor already loaded. Skip.");
    var reloadFn = fanplayr.adaptor && fanplayr.adaptor.onReload;
    if (typeof reloadFn === "function") {
      reloadFn();
    }
  }
} else {
  log("Adaptor prevent loading in frame: " + (window.frameElement && window.frameElement.src));
}

},{"./config":3,"./emitter":4,"./fanplayr-init":5,"./log":7,"./utils":11,"__project/boot.js":12,"__project/main.js":13}],2:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform.capabilities;

},{"./adaptor":1}],3:[function(require,module,exports){
"use strict";

// Magically replaced, do not edit.
module.exports = {
  "type": "adaptor",
  "name": "fanplayr-website",
  "version": "1.0.4",
  "accountKey": "123a12bb9dfd5afbc0480c5c54da8acf",
  "adaptorVersion": 1
};

},{}],4:[function(require,module,exports){
"use strict";

function Emitter() {
  this.events = {};

  // Last emitted values.
  this.emitCache = {};
}

var arraySlice = Array.prototype.slice;

Emitter.mixin = function (obj) {
  var emitter = new Emitter();

  obj.on = function () {
    return emitter.on.apply(emitter, arraySlice.call(arguments));
  };

  obj.emit = function () {
    return emitter.emit.apply(emitter, arraySlice.call(arguments));
  };

  obj.$emitter = emitter;

  return obj;
};

Emitter.prototype = {
  on: function on(eventType, options, cb) {
    var self = this;

    if (typeof options === "function") {
      var temp = cb;
      cb = options;
      options = temp;
    }

    if (!options) {
      options = {};
    }

    var listeners = self.events[eventType];
    if (!listeners) {
      listeners = self.events[eventType] = [];
    }

    var listener = {
      options: options,
      cb: cb
    };

    if (options.init) {
      var cached = self.emitCache[eventType];
      if (cached) {
        self.invoke(listener, cached);
      }
    }

    listeners.push(listener);
  },

  emit: function emit(eventType) {
    var self = this;

    var args = arraySlice.call(arguments, 1);
    self.emitCache[eventType] = args;

    var listeners = self.events[eventType];
    if (listeners) {
      for (var i = 0, len = listeners.length; i < len; i++) {
        self.invoke(listeners[i], args);
      }
    }
  },

  invoke: function invoke(listener, args) {
    listener.cb.apply(listener.options.context || listener.cb, args);
  }
};

module.exports = Emitter;

},{}],5:[function(require,module,exports){
"use strict";

var log = require("./log");

module.exports = function (initFn, environment) {
  (function (window, document) {
    var fanplayr = window.fanplayr;
    var fanplayrReady = "fanplayr_ready";

    /*
      fanplayr._loadState
        falsy: not initalized
        1: loading
        2: ready
    */

    if (fanplayr) {
      var loadState = fanplayr._loadState;

      if (loadState === 2) {
        // Already loaded.
        initFn(fanplayr);
      }

      var origFn = window[fanplayrReady];
      window[fanplayrReady] = function (f, p) {
        if (origFn) {
          origFn(f, p);
        }
        initFn(f);
      };

      if (!loadState) {
        // Fanplayr hasn't begun loading, so load it.
        var script = document.createElement("script");
        script.async = true;
        script.src = "//cdn.fanplayr.com/client/" + (environment || "production") + "/loader.js";
        var first = document.getElementsByTagName("script")[0];
        if (first) {
          first.parentNode.insertBefore(script, first);
        } else {
          document.body.appendChild(script);
        }
      }
    }
  })(window, document);
};

},{"./log":7}],6:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.$;

},{"./adaptor":1}],7:[function(require,module,exports){
"use strict";

var console, logFn;

try {
  console = window.console || window.top.console;
  logFn = console && console.log;
} catch (e) {}

var noop = function noop() {};

function create(name) {
  var log = noop;
  if (logFn) {
    if (typeof logFn.bind === "function") {
      log = logFn.bind(console, "[" + name + "]");
    } else {
      log = function log() {
        var args = Array.prototype.slice.call(arguments);
        args.unshift("[" + name + "]");
        logFn(args.join(""));
      };
    }
  }

  log.create = create;
  log.info = log;
  log.error = log;

  return log;
}

module.exports = create("adaptor");

},{}],8:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform;

},{"./adaptor":1}],9:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").stash;

},{"./adaptor":1}],10:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform.state;

},{"./adaptor":1}],11:[function(require,module,exports){
"use strict";

// modules.exports = require("./req")("platform/utils");

var _ = module.exports = {};
var log = require("./log");

_.parseInt = function (text, defaultValue) {
  var value = _parseFloat(text, defaultValue);
  if (typeof value === "number") {
    return Math.floor(value);
  }
  return value;
};

/*
  Extracts the first matched float from a string.

  @param string text       The input string.
  @param * defaultValue    The default value to return if parsing fails.
  @param string chars      Special characters.

  About `chars`:
    - Must include at least two characters.
    - The first character must be the thousands separator.
    - The second character must be the decimal character.
    - Any other characters will be matched as part of the number, but stripped before converting to a float.
*/
_.parseFloat = _parseFloat;
function _parseFloat(text, defaultValue, chars) {
  // Get defaults.
  if (typeof defaultValue === "undefined") {
    defaultValue = _parseFloat.defaultValue;
  }
  chars = chars || _parseFloat.chars;

  text = String(text);
  var value = defaultValue;
  if (text) {
    // Find the first occurance of a group of numbers and special characters. Must match:
    // - Optional negative character `-`
    // - Optional single special character
    // - One or more digits
    // - Zero or more digits and special characters
    var pattern = new RegExp("(-?[" + chars.substr(0, 2) + "]?\\d+[0-9" + chars + "]*)");
    var match = text.match(pattern);
    if (match) {
      // Build a regular expression of bad characters to strip out.
      // The first char is the separator.
      var badChars = chars.charAt(0);
      if (chars.length > 2) {
        // Add any others.
        badChars += chars.substr(2);
      }
      pattern = new RegExp("[" + badChars + "]", "g");

      // Strip bad characters.
      text = match[1].replace(pattern, "");

      // Replace the decimal character with an actual period
      // and parse as float.
      value = parseFloat(text.replace(chars.charAt(1), "."));

      if (isNaN(value)) {
        return defaultValue;
      }
    }
  }
  return value;
};

_parseFloat.defaultValue = 0;
_parseFloat.chars = ",.";

var decodeHtmlEl;
_.decodeHtml = function (value) {
  if (value) {
    if (!decodeHtmlEl) {
      decodeHtmlEl = document.createElement("div");
    }
    decodeHtmlEl.innerHTML = value;
    return decodeHtmlEl.firstChild.nodeValue;
  }
  return value;
};

_.waitUntil = function (evaluate, intervalMs, callback) {
  if (arguments.length === 2) {
    callback = intervalMs;
    intervalMs = 500;
  }

  if (typeof evaluate === "string") {
    var evalPath = evaluate;
    evaluate = function evaluate() {
      return eval(evalPath);
    };
  }

  var iterator = function iterator() {
    var value = evaluate();
    if (value) {
      callback(value);
    } else {
      setTimeout(iterator, intervalMs);
    }
  };

  iterator();
};

},{"./log":7}],12:[function(require,module,exports){
if (window.fanplayr) {
  window.fanplayr.cdp = {
    enabled: true,
    trackPages: true,
    trackOrders: true
  };
}

},{}],13:[function(require,module,exports){
var log = require('__hodor/log.js');
var platform = require('__hodor/platform.js');
var state = require('__hodor/state.js');
var stash = require('__hodor/stash.js');
var _ = require('__hodor/utils.js');
var capabilities = require('__hodor/capabilities.js');
var $ = require('__hodor/jquery.js');
var JSON = window.JSON;

var user = state.user;
var page = state.page;
var product = page.product;
var cart = state.cart;
var order = state.order;

page.type = getPageType();

if (page.type === "order") {
  trackOrder();
} else {
  trackPage();
}

/*
Get a Demo : /get-a-demo-submitted
Tech Support: /tech-support-submitted
Become a Partner: /become-a-partner-submitted
*/
if (window.location.pathname === "/contact-us") {
  $('.submit-button.w-button').on('click', function () {
    page.url = "https://www.fanplayr.com/contact-us/" + $(".tab_link.w-tab-link.w--current").text().toLowerCase().replace(/ /g, "-") + '-submitted';
    trackPage();
  });
} else {
  $('.submit-button.w-button').on('click', function () {
    log("submit button clicked");
    page.url = window.location.href.replace(/\/$/, '') + "/get-a-demo-submitted";
    trackPage();
  });
}

if (_.parseUrl(window.location.href).params.contactform) {
  $("#ContactForm-wrapper")[0].scrollIntoView();
}

function getPageType() {
  if (window.location.pathname === "/") {
    return "home";
  }
  return "page";
}

function trackPage() {
  // if (page.type === "cart") {
  //   log(JSON.stringify(state.cart, null, 2));
  // } else {
  //   log(JSON.stringify(state.page, null, 2));
  // }
  platform.trackPage();
}

function trackOrder() {
  // log(JSON.stringify(state.order, null, 2));
  platform.trackOrder();
}

},{"__hodor/capabilities.js":2,"__hodor/jquery.js":6,"__hodor/log.js":7,"__hodor/platform.js":8,"__hodor/stash.js":9,"__hodor/state.js":10,"__hodor/utils.js":11}]},{},[1]);